<template>
  <div id="order">
    <top-tab
      v-if="topTap"
      :tapList="tapList"
      @thisTabClick="thisTabClick"
      :thisTab="thisTab"
      gorouter="OrderSearch"
    >
    </top-tab>

    <no-data
      v-if="order_list && order_list.length == 0 && onloadBoo == false"
    ></no-data>

    <!-- <template >
        <order-list></order-list>
    </template> -->

    <!-- <keep-alive>
      <component
      v-bind:is="currentTabComponent"
      :statics='statics'
      >
      </component>

    </keep-alive> -->
    <van-list
      v-else
      error-text="请求失败，点击重新加载"
      v-model="loading"
      :finished="finished"
      finished-text="已无更多明细"
      @load="onLoad"
    >
      <tabOrderAll :order_list="order_list" :onloadBoo="onloadBoo">
      </tabOrderAll>
    </van-list>
  </div>
</template>
<script>
// import { List } from 'vant';
// import Vue from 'vue';
import topTab from "@/components/topTab/";
import noData from "@/components/noData/";
import orderList from "./list/index.vue";
import { List, Toast } from "vant";
import { post } from "@get/http";

// Vue.component('tab-order-all',orderList)
// Vue.component('tab-order-pay',orderList)

export default {
  components: {
    topTab,
    noData,
    tabOrderAll: orderList,
    vanList: List,
  },
  props: {
    topTap: {
      default: true,
    },
    goodsName: {
      default: "",
    },
  },
  data: () => ({
    thisTab: "all",
    active: 1,
    loading: false,
    finished: false,
    page: 1, //当前订单页
    data: false, //订单全部数据
    order_list: [], //订单数据
    onloadBoo: true, //数据初始化
    per_page: 10,
    statics: "",
    currentTab: "",
    state_type: "",
    tapList: [
      {
        title: "全部",
        type: "all",
        state_type: "",
      },
      {
        title: "待付款",
        type: "pay",
        state_type: "state_new",
      },
      {
        title: "待发货",
        type: "wait",
        state_type: "state_pay",
      },
      {
        title: "待收货",
        type: "get",
        state_type: "state_send",
      },
      {
        title: "待评价",
        type: "review",
        state_type: "state_success",
      },
    ],
  }),
  watch: {
    goodsName() {
      this.resetting();
    },
  },
  activated() {
    // console.log('???');
  },
  created() {
    Toast.clear();
    let query = this.$route.query;
    if (query?.state_type && query?.tab) {
      this.thisTab = query.tab;
      this.state_type = query.state_type;
    }
    //do something after creating vue instance
    Toast.loading({
      message: "加载中...",
      loadingType: "spinner",
      overlay: true,
      forbidClick: true,
      duration: 0,
    });
  },
  methods: {
    async onLoad() {
      this.onloadBoo = true;
      this.loading = true;

      let result = await post("/Memberorder/order_list", {
        data: {
          page: this.page,
          per_page: this.per_page,
          order_key: "",
          state_type: this.goodsName.length == 0 ? this.state_type : "",
          goods_name: this.goodsName,
        },
      });

      this.data = result;
      this.order_list.push(...result.order_group_list);
      this.page++;
      this.loading = false;
      if (result.order_group_list.length < this.per_page) {
        this.loading = true;
        this.finished = true;
        this.onloadBoo = false;
      }
      Toast.clear();
    },
    thisTabClick(type) {
      this.state_type = type;
      this.resetting();
    },
    resetting() {
      // 重置
      this.page = 1;
      this.loading = false;
      this.finished = false;
      this.order_list = [];

      this.onLoad();
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@css/mixin.scss";
#order {
  background: #ededed;
  padding-top: rem(20);
  // height: 100%;
}
</style>
