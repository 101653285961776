<template>
  <span class="cancel" @click.stop="returnFoo"><slot></slot></span>
</template>
<script>
export default {
  name: "",
  data: () => ({}),
  methods: {
    returnFoo() {
      this.$emit("click");
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@css/mixin.scss";
.cancel,
.endOrder {
  display: inline-block;
  min-width: rem(140);
  height: rem(52);
  line-height: rem(52);
  border-radius: rem(26);
  text-align: center;
}
.cancel {
  background: #ffffff;
  border: 1px solid #ebebeb;
  color: #333;
}
.endOrder {
  color: #ff4747;
  border: 1px solid #ff4747;
  color: #ff4747;
}
</style>
