<template>
  <div class="">
    <template v-for="(items, index) in order_list">
      <div
        class="order_ul"
        v-for="item in items.order_list"
        @click.stop="
          $router.push({
            name: 'OrderDetail',
            query: { order_id: item.order_id },
          })
        "
      >
        <!-- <div class="order_ul" v-for="item in items.order_list" > -->
        <div class="order_li">
          <div class="top flex-container">
            <img src="@image/order/icon_shop.png" />
            <span style="margin-right: 0.2rem">{{ item.store_name }}</span>
            <p class="flex1">{{ item.add_time | time }}</p>

            <span
              v-if="
                item.state_desc == '交易完成' || item.state_desc == '已取消'
              "
              >{{ item.state_desc }}</span
            >
            <span class="yes" v-else>{{ item.state_desc }}</span>
          </div>

          <div
            class="middel flex-container"
            v-for="(elem, i) in item.extend_order_goods"
          >
            <img :src="elem.goods_image_url" class="pro_img" />
            <div class="info">
              <h2>{{ elem.goods_name }}</h2>
              <span class="sku">数量:1个</span>
              <!-- <b class='active'>新人首单特价</b> -->
            </div>
            <div class="price">
              <p><i>￥</i>{{ elem.goods_pay_price }}</p>
              <b>x{{ elem.goods_num }}</b>
              <order-btn
                style="margin-top: 0.4rem"
                class="cancel"
                v-if="elem.if_evaluation == 0"
                @click="evaluate(elem.rec_id, index, i)"
                >去评价</order-btn
              >
            </div>
          </div>

          <p class="money">
            共1件 应付款：<b><i>￥</i>{{ item.order_amount }}</b>
          </p>

          <div class="btn flex-container">
            <order-btn
              class="cancel"
              v-if="item.if_cancel"
              @click="cancel(item, items)"
              >取消订单</order-btn
            >
            <!-- <order-btn class='cancel'   v-if="item.state_desc == '交易完成'" >再次购买</order-btn> -->
            <order-btn
              class="cancel"
              v-if="item.if_deliver"
              @click="
                deliver(
                  item.shipping_code,
                  item.extend_order_common.reciver_info.phone
                )
              "
              >查看物流</order-btn
            >
            <!-- <order-btn class='endOrder' v-if="item.if_receive"    @click="receive(item.order_id)">确认收货</order-btn> -->
            <order-btn
              class="endOrder"
              v-if="item.if_receive"
              @click="receive(item.order_id, item)"
              >确认收货</order-btn
            >
            <!-- <order-btn
              class="cancel"
              v-if="item.if_evaluation"
              @click="evaluate(item.rec_id)"
              >去评价</order-btn
            > -->

            <!-- <order-btn class='cancel'   v-if="item.if_refund_cancel" @click="refund(item.extend_order_goods,item.order_id)">售后</order-btn> -->

            <!-- <order-btn class='endOrder'   v-if="item.refund_list && item.refund_list.refund_state !=3" >售后中</order-btn> -->

            <!-- <order-btn class='cancel'   v-if="item.if_refund_cancel" @click="refund(item.order_id)">售后</order-btn> -->
            <!-- <order-btn class='endOrder'   v-if="item.if_lock">退款中</order-btn> -->
            <div
              @click.stop="goPay(items.pay_sn)"
              class="flex-container"
              v-if="items.pay_amount && items.pay_amount > 0"
              style="margin-left: 15px"
            >
              <b class="maybe">去付款</b>
              <!-- <b class='go'>去付款</b>
              <b class='time'>14:49</b> -->
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import orderBtn from "../components/orderBtn.vue";
import { Dialog, Toast } from "vant";
import { pay } from "@get/pay";
import { mapState, mapMutations } from "vuex";
import { getCancelOrder, getReceiveOrder } from "../js/";
import { post } from "@get/http";
export default {
  props: ["order_list"],
  data: () => ({
    submitFrom: {
      uniapp: 0, // 是否是小程序
      pay_type: "pay_new", // 订单支付显示的数据
      payment_info: {
        password: "", // 支付密码
        rcb_pay: 0, // 是否使用充值卡进行支付
        pd_pay: 0, // 是否使用预存款进行支付
        payment_code: "alipay_h5", // 支付方式
      },
    },
    payment_list: {}, //可以支付的列表
  }),
  components: {
    orderBtn,
  },
  computed: {
    ...mapState({
      setReviewState: (state) => state.order.setReviewState,
      setReviewArrayPos: (state) => state.order.setReviewArrayPos,
      token: (state) => state.user.token,
    }),
  },

  watch: {
    setReviewState() {
      try {
        if (this.setReviewState == true) {
          this.order_list[
            this.setReviewArrayPos[0]
          ].order_list[0].extend_order_goods[
            this.setReviewArrayPos[1]
          ].if_evaluation = 1;
          this.fChangeReviewState(false);
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
  methods: {
    ...mapMutations({
      fChangeReviewArrayPos: "fChangeReviewArrayPos",
      fChangeReviewState: "fChangeReviewState",
    }),
    deliver(orderId, phone) {
      //物流
      this.$router.push({
        name: "OrderMail",
        query: {
          mailid: orderId,
          phone,
        },
      });
      // window.location.href = "https://m.kuaidi100.com/result.jsp?nu=" + orderId;
    },
    // refund (order_id) {
    refund(product_list, order_id) {
      //售后
      // this.$router.push({ name: 'MemberRefundForm', query: { order_id } })  //旧版本
      this.$router.push({
        name: "OrderServiceReason",
        query: { product_list, order_id },
      });
    },
    evaluate(rec_id, index, i) {
      //去评价
      this.fChangeReviewArrayPos([index, i]);
      this.$router.push({ name: "OrderReview", query: { rec_id } });
    },
    async goPay(pay_sn) {
      // 支付
      let { pay_info } = await post("/memberbuy/pay", {
        data: {
          pay_sn,
        },
      });
      this.payment_list = pay_info?.payment_list[0];
      this.submitFrom.payment_info.payment_code = this.payment_list.payment_code;
      if (this.payment_list.payment_code == "wxpay_minipro") {
        wx.miniProgram.redirectTo({
          url:
            "../pay/pay?action=" +
            this.submitFrom.pay_type +
            "&key=" +
            this.token +
            "&pay_sn=" +
            pay_sn +
            "&password=" +
            this.submitFrom.payment_info.password +
            "&rcb_pay=" +
            this.submitFrom.payment_info.rcb_pay +
            "&pd_pay=" +
            this.submitFrom.payment_info.pd_pay +
            "&payment_code=" +
            this.submitFrom.payment_info.payment_code,
        });
        return;
      }

      pay(
        pay_sn,
        this.submitFrom.pay_type,
        this.submitFrom.payment_info,
        this.token,
        this.submitFrom.uniapp
      )
        .then((result) => {
          Toast("正在准备支付");
          document.write(result);
        })
        .catch(function(error) {
          if (error.message) {
            Toast(error.message);
          } else {
            console.error(error);
            // document.write(error);
          }
        });

      // this.$router.push({
      //   name: "Buy",
      //   query: { pay_sn: paySn, pay_type: "pay_new" },
      // });
    },
    cancel(orderInfo, orderInfos) {
      // 取消订单
      Dialog.confirm({
        title: "十九匠商城通知",
        message: "确定要取消该订单吗？",
      }).then(async () => {
        await getCancelOrder(orderInfo.order_id);
        orderInfo.if_cancel = false;
        orderInfo.state_desc = "已取消";
        orderInfos.pay_amount = -1;
      });
    },
    receive(orderId, order) {
      Dialog.confirm({
        title: "十九匠商城通知",
        message: "确定该订单已收货吗？",
      }).then(async () => {
        await getReceiveOrder(orderId);
        order.if_receive = false;
        order.state_desc = "交易完成";
        order.if_evaluation = true;
        Toast("十九匠商城：收货成功");
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@css/mixin.scss";

.order_ul {
  width: rem(710);
  margin: rem(20) auto;
  background: #ffffff;
  border-radius: rem(20);
  padding: 0 rem(32);
  box-sizing: border-box;
  .order_li {
    margin-bottom: rem(20);
    padding: rem(24) 0;

    .top {
      align-items: center;
      img {
        width: rem(36);
        height: rem(36);
        background: #ff4747;
        border-radius: rem(4);
        display: block;
        margin-right: rem(12);
      }
      p {
        font-size: rem(22);

        color: #333333;
      }
      span {
        font-size: rem(22);

        color: #b3b3b3;
      }
      .yes {
        color: #ff4848;
      }
    }

    .middel {
      margin-top: rem(32);

      .pro_img {
        width: rem(140);
        height: rem(140);
        border-radius: rem(8);
        display: block;
        margin-right: rem(40);
        // margin-right: rem(24);
      }
      .info {
        width: rem(380);
        margin-right: rem(48);
        h2 {
          font-size: rem(24);
          font-weight: bold;

          color: #333333;
        }
        .sku {
          font-size: rem(22);
          font-weight: bold;

          color: #b3b3b3;
          display: block;
          margin-top: rem(2);
        }
        .active {
          display: inline-block;
          margin-top: rem(6);
          padding: rem(6);

          height: rem(18);
          line-height: rem(18);
          text-align: center;
          font-size: rem(18);
          font-weight: bold;
          color: #ff4848;
          background: #ffebeb;
          border-radius: rem(4);
        }
      }
      .price {
        i {
          font-size: rem(18);
        }
        p {
          font-size: rem(22);

          color: #333333;
          display: block;
          text-align: right;
        }
        b {
          text-align: right;
          display: block;
          font-size: rem(22);

          color: #b3b3b3;
        }
      }
    }
    .money {
      font-size: rem(24);
      color: #808080;
      text-align: right;
      margin-top: rem(20);
      i {
        font-size: rem(20);
      }
      b {
        font-size: rem(24);
        font-weight: bold;
        color: #333;
      }
    }
    .btn {
      font-size: rem(22);
      margin-top: rem(32);
      text-align: center;
      justify-content: flex-end;
      span {
        margin-left: rem(28);
      }
      .go {
        color: #fff;
        display: inline-block;
        width: rem(92);
        height: rem(52);
        line-height: rem(52);
        background: linear-gradient(90deg, #ff4747, #e82c2c);
        border-radius: rem(26) 0 0 rem(26);
        // font-family: 'pf';

        // display: inline-block;
      }
      .maybe {
        width: rem(200);
        height: rem(54);
        line-height: rem(54);
        background: linear-gradient(90deg, #d81a1a, #ff4848);
        border-radius: rem(26);
        color: #fff;
        font-size: rem(22);
      }
      .time {
        display: inline-block;
        width: rem(92);
        height: rem(52);
        background: linear-gradient(90deg, #ff4747, #d81919);
        border-radius: 0px rem(26) rem(26) 0px;
        line-height: rem(52);
        // display: inline-block;
        // font-family: 'pfb';
        color: #fff;
      }
    }
  }
}
</style>
