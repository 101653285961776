import {
  post
} from '@get/http'


export const getCancelOrder = (order_id) => {
  /**
   * @method getCancelOrder  取消订单
   * @param order_id  订单ID
   * @param {promise}  
   */
  return post(
    '/Memberorder/order_cancel', {
      data: {
        order_id
      }
    }
  )
}

// 订单收货
export const getReceiveOrder = (order_id) => {
  /**
   * @method getReceiveOrder  订单收货
   * @param order_id  订单ID
   * @param {promise}  
   */
  return post(
    '/Memberorder/order_receive', {
      data: {
        order_id
      }
    }
  )
}



// 获取订单信息
export const getOrderInfo = (order_id) => {
  /**
   * @method getOrderInfo  获取订单信息
   * @param order_id  订单ID
   * @param {promise}  
   */
  return post(
    '/Memberorder/order_info', {
      data: {
        order_id
      }
    }
  )
}