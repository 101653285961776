import {
  raw,
  post
} from './http'
export const pay =
  (paySn, payType, data, key, uniapp = false) =>
  raw(
    process.env.VUE_APP_HOST + '/index.php?s=api/Memberpayment/' + payType + '/pay_sn/' + paySn + '/password/' + data.password + '/rcb_pay/' + data.rcb_pay + '/pd_pay/' + data.pd_pay + '/payment_code/' + data.payment_code + '/key/' + key + '/uniapp/' + uniapp + '/random_number/' + Math.random() + '/'
  )






export const PreSalePay =
  (pay_sn, payment_code, key, is_deposit = 1) =>


  post('/v3.MemberPresaleGoods/createPay', {
    params: {
      pay_sn,
      payment_code,
      key,
      is_deposit,
      random_number: Math.random(),
    }
  }, {
    origin: true
  })